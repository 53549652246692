import styled, { css } from "styled-components";
import { theme } from "@sqymagma/theme";

const ActionButton = styled.div`
  display: flex;
  cursor: pointer;
  gap: ${(p) => p.theme.spacing.xxxs};
  align-items: center;

  svg {
    path,
    circle {
      fill: ${(p) => p.theme.colors.text01};
    }
  }

  &:hover {
    span {
      color: ${(p) => p.theme.colors.interactive01Hover};
    }
    svg {
      path,
      circle {
        fill: ${(p) => p.theme.colors.interactive01Hover};
      }
    }
  }
`;

const Reaction = styled.div<{ $isUserReaction: boolean; $isUserMood: boolean }>`
  ${theme("textStyle.body")};
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing.xxxs};
  padding: ${(p) => p.theme.spacing.xxxs};
  border-radius: ${(p) => p.theme.radii.s};
  border: 1px solid
    ${(p) =>
      p.$isUserReaction
        ? p.theme.colors.interactive05Active
        : p.$isUserMood
        ? p.theme.colors.ui06
        : p.theme.colors.line01};
  background-color: ${(p) =>
    p.$isUserReaction
      ? p.theme.colors.interactive05Active
      : p.$isUserMood
      ? p.theme.colors.brand06
      : p.theme.colors.tertiaryBackground};
  cursor: pointer;

  &:hover {
    ${(p) =>
      !p.$isUserReaction &&
      css`
        background-color: ${(p) => p.theme.colors.interactive05Hover};
      `}
    & > span:first-child {
      transform: scale(1.2);
    }
  }
`;

const ReactionTooltip = styled.div<{ $columns: number }>`
  display: grid;
  gap: ${(p) => p.theme.spacing.xxxs};
  grid-template-columns: repeat(${(p) => p.$columns}, auto);
`;

export { ActionButton, Reaction, ReactionTooltip };
