import React from "react";
import { HStack, VStack, Text, Box } from "@sqymagma/elements";
import { Route, useLocation } from "react-router-dom";

import { useApp, useAuth } from "@contexts";
import { SideModal, WeatherWidget, Config } from "@components";
import { Avatar, Button } from "@elements";
import { IconNames } from "@elements/Icon";
import { useModal } from "@hooks";

import Changelog from "./Changelog";
import CommentsNotifier from "./CommentsNotifier";
import * as S from "./style";

const Header = () => {
  const {
    serviceWorker: { showReload, reloadPage },
  } = useApp();
  const auth = useAuth();
  const { user } = auth;
  const { isOpen, toggleModal } = useModal();

  const location = useLocation();
  const isHome = location.pathname === "/";

  const handleSignOut = () => {
    auth.signOut();
  };

  const menuOptions = [
    ...(showReload
      ? [
          {
            title: "Actualizar y reiniciar",
            icon: "sync" as IconNames,
            action: reloadPage,
          },
        ]
      : []),
    {
      title: "Configuración",
      action: toggleModal,
    },
    {
      title: "Salir",
      action: handleSignOut,
    },
  ];

  const User = () =>
    user && (
      <HStack gap={{ default: "xxs", m: "xs" }} alignItems="center">
        <Avatar image={user.image} name={user.name} size="l" />
        <VStack display={{ default: "none", m: "block" }}>
          <Text textStyle="subtitle01" caps>
            {user.name}
          </Text>
          <Text textStyle="bodyInline">{user.email}</Text>
        </VStack>
      </HStack>
    );

  return (
    <>
      <S.Header
        $isHome={isHome}
        mb={{ default: "xxs", s: "s" }}
        display={{ default: "none", s: "flex" }}
      >
        <Route exact path="/">
          <S.WeatherWrapper>
            <WeatherWidget />
          </S.WeatherWrapper>
        </Route>
        <HStack gap="xs" alignItems="center">
          <HStack gap="s" mr="xxs" alignItems="center">
            <CommentsNotifier />
            <Changelog />
          </HStack>
          <User />
          <Box position="relative">
            <Button
              appearance="tertiary"
              leftIcon="menu"
              tooltip="Opciones"
              rounded
              optionsPosition="down-left"
              options={menuOptions}
            />
            {showReload && <S.Badge />}
          </Box>
        </HStack>
      </S.Header>
      <SideModal title="Configuración" isOpen={isOpen} hide={toggleModal}>
        <Config />
      </SideModal>
    </>
  );
};

export default Header;
