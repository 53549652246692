import styled, { createGlobalStyle } from "styled-components";
import { theme } from "@sqymagma/theme";

const JoditGlobalStyles = createGlobalStyle`
  .jodit * {
    font-family: ${theme("fontFamily.primary")} !important;
  }

  .jodit-popup {
    border-radius: ${theme("radii.m")};
    box-shadow: ${theme("shadows.tiny")};
    border: 1px solid ${theme("colors.line07")};
    overflow: hidden;

    .jodit-popup__content {
      background-color: ${theme("colors.senaryBackground")};

      .jodit-toolbar-editor-collection {
        background: ${theme("colors.senaryBackground")};
      }

      .jodit-toolbar-editor-collection_mode_horizontal:after {
        display: none;
      }

      .jodit-toolbar-button {
        &[data-ref="brush"],
        &[data-ref="pencil"],
        &[data-ref="valign"],
        &[data-ref="left"],
        &[data-ref="file"] {
          display: none;
        }
      }
    }
  }

  .jodit-ui-button,
  .jodit-ui-button:hover {
    background-color: ${theme("colors.secondaryBackground")};
    color: ${theme("colors.text02")};
    &:hover {
      background-color: ${theme("colors.interactive01Hover")};
      color: ${theme("colors.text02")};
    }
  }

  .jodit-ui-input__wrapper,
  .jodit-ui-input__input {
    border-radius: ${theme("radii.s")};
    background-color: ${theme("colors.senaryBackground")};
    border-color: ${theme("colors.line07")};
    color: ${theme("colors.text01")};
    &::selection{
      background: ${theme("colors.brand06")};
    }
  }

  .jodit-ui-input_focused_true .jodit-ui-input__wrapper {
    border-color: ${theme("colors.brand01")};
  }

  .jodit-toolbar-button__icon svg {
    fill: ${theme("colors.text03")} !important;
  }

  .jodit-toolbar-button__button:hover:not([disabled]) {
    background-color: ${theme("colors.quaternaryBackground")};
  }

  .jodit-toolbar-button__button[aria-pressed=true]:not([disabled]) {
    background-color: ${theme("colors.quaternaryBackground")};
  }
`;

const EditorWrapper = styled.div`
  position: relative;

  .jodit__container {
    background-color: ${(p) => p.theme.colors.senaryBackground};
    border: 1px solid ${(p) => p.theme.colors.line07};
    overflow: hidden;
    font-family: ${(p) => p.theme.fontFamily.primary};

    .jodit-toolbar__box {
      background-color: ${(p) => p.theme.colors.senaryBackground};
      border-color: ${(p) => p.theme.colors.line07};
    }

    .jodit-toolbar-editor-collection:after {
      background-color: ${(p) => p.theme.colors.senaryBackground};
    }

    .jodit-placeholder {
      ${theme("textStyle.body")};
      color: ${(p) => p.theme.colors.disabled01};
    }

    .jodit-ui-separator {
      border-color: ${(p) => p.theme.colors.line07};
    }
  }

  .jodit__editor {
    ${theme("textStyle.body")};
    color: ${(p) => p.theme.colors.text01};
    background-color: ${(p) => p.theme.colors.senaryBackground};

    & a {
      color: ${(p) => p.theme.colors.link01};
      text-decoration: underline;
    }

    & *::selection {
      background: ${(p) => p.theme.colors.brand06};
      color: ${(p) => p.theme.colors.text01};
    }
  }

  .jodit-resizer {
    outline-color: ${(p) => `${p.theme.colors.brand01}bb`};
    & > div {
      background-color: ${(p) => p.theme.colors.brand01};
      border-radius: 2px;
    }
  }
`;

const GifSearch = styled.div`
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(p) => p.theme.colors.senaryBackground};
  border-radius: ${(p) => p.theme.radii.m};
  padding: ${(p) => p.theme.spacing.xs};
  box-shadow: ${(p) => p.theme.shadows.tiny};
  border: 1px solid ${(p) => p.theme.colors.line07};

  .reactGiphySearchbox-searchForm-input {
    ${theme("textStyle.bodyInline")};
    color: ${(p) => p.theme.colors.text01};
    background-color: ${(p) => p.theme.colors.senaryBackground};
    border: none;
    border-bottom: 2px solid ${(p) => p.theme.colors.line02};
    &:hover {
      border-color: ${(p) => p.theme.colors.brand01};
    }
    &:focus {
      border-color: ${(p) => p.theme.colors.line02};
    }
  }

  .reactGiphySearchbox-listWrapper::-webkit-scrollbar {
    width: 0;
  }

  .react-giphy-searchbox-list-item {
    border-radius: 4px;
    overflow: hidden;
  }

  .reactGiphySearchbox-message {
    ${theme("textStyle.body")};
  }
`;

export { GifSearch, EditorWrapper, JoditGlobalStyles };
