import React, { useCallback, useMemo, useState } from "react";
import { VStack, HStack, Text, Flex, Box } from "@sqymagma/elements";
import { motion } from "framer-motion";

import { MoodState } from "@types";
import { Modal, MoodWidget, WidgetBox } from "@components";
import { Avatar, Button, Icon, HTMLRender, Tooltip } from "@elements";
import EmojiSelector, { EmojiClickData } from "@elements/EmojiSelector";
import { IconNames } from "@elements/Icon";
import { getPostTimeFromUnix, getUserDataFromEmail } from "@helpers";
import { useApp, useAuth, useToast } from "@contexts";
import { useModal } from "@hooks";
import { remoteApi } from "@services";

import MoodReplyModal from "./MoodReplyModal";
import * as S from "./style";

interface MoodCardProps extends MoodState {
  reloadMoods: () => void;
  fromNotification?: boolean;
  updateMoodData: (moodUpdated: MoodState) => void;
}

const moodIcons: Array<IconNames> = [
  "reallyBadMoodOn",
  "badMoodOn",
  "neutralMoodOn",
  "happyMoodOn",
  "reallyHappyMoodOn",
];

const MoodCard = (props: MoodCardProps) => {
  const { reloadMoods, updateMoodData, fromNotification, ...moodData } = props;
  const {
    id,
    created,
    hidden,
    message,
    mood,
    user: moodUserEmail,
    comments,
    reactions,
  } = moodData;

  const { people } = useApp();
  const { user } = useAuth();
  const [repliesCount, setRepliesCount] = useState(comments?.length || 0);
  const { addToast } = useToast();
  const [openEmojis, setOpenEmojis] = useState(false);

  const { isOpen: isDeleteOpen, toggleModal: toggleDeleteModal } =
    useModal(false);
  const { isOpen: isUpdateOpen, toggleModal: toggleUpdateModal } =
    useModal(false);
  const { isOpen: isCommentsOpen, toggleModal: toggleCommentsModal } = useModal(
    !!fromNotification
  );

  const moodUser = getUserDataFromEmail(moodUserEmail, people);
  const isUserMood = moodUser?.email === user?.email;

  const handleDelete = async () => {
    toggleDeleteModal();
    const isDeleted = await remoteApi.deleteMood(id);
    if (isDeleted) {
      reloadMoods();
      addToast("Mensaje eliminado");
    } else {
      addToast("⚠️ ¡Oops! No se pudo eliminar el mensaje");
    }
  };

  const handleOnUpdate = async () => {
    toggleUpdateModal();
    reloadMoods();
  };

  const updateReactions = useCallback(
    ({ emoji }: Pick<EmojiClickData, "emoji">) => {
      remoteApi.updateMoodReactions(id, emoji).then((updatedMood) => {
        updateMoodData(updatedMood);
      });
      setOpenEmojis(false);
    },
    [id, updateMoodData]
  );

  const moodContent = useMemo(
    () => (
      <VStack gap="xs">
        {/* Header */}
        <Flex justifyContent="space-between">
          <HStack gap="xxs" alignItems="center">
            <Avatar
              image={moodUser?.photo}
              name={moodUser?.name || moodUserEmail}
            />
            <VStack>
              <Text
                textStyle="subtitle01"
                mr="xxs"
                style={{ fontWeight: "bold", wordBreak: "break-all" }}
              >
                {moodUser?.name || moodUserEmail}
              </Text>
              <Text textStyle="bodyInline" color="text01">
                {getPostTimeFromUnix(created._seconds)}
              </Text>
            </VStack>
          </HStack>
          <Icon name={moodIcons[mood - 1]} width={55} height={55} />
        </Flex>
        {/* Message */}
        {message && <HTMLRender>{message}</HTMLRender>}
      </VStack>
    ),
    [
      created._seconds,
      message,
      mood,
      moodUser?.name,
      moodUser?.photo,
      moodUserEmail,
    ]
  );

  const Reaction = useCallback(
    ({
      emoji,
      users,
      hideTooltip,
    }: {
      emoji: string;
      users: Array<string>;
      hideTooltip?: boolean;
    }) => {
      const handleReactionClick = () => updateReactions({ emoji });
      const TooltipContent = (
        <S.ReactionTooltip $columns={Math.min(users.length, 6)}>
          {users.map((email) => {
            const { photo, name } = getUserDataFromEmail(email, people);
            return <Avatar key={email} image={photo} name={name} size="xs" />;
          })}
        </S.ReactionTooltip>
      );
      return (
        <Tooltip content={hideTooltip ? null : TooltipContent} hideOnClick>
          <S.Reaction
            $isUserReaction={!!user && users.includes(user?.email)}
            $isUserMood={isUserMood}
            onClick={handleReactionClick}
          >
            <span>{emoji}</span>
            <span>{users.length}</span>
          </S.Reaction>
        </Tooltip>
      );
    },
    [people, updateReactions, user, isUserMood]
  );

  const reactionsKeys = reactions && Object.keys(reactions);
  const showedReactions = reactionsKeys?.slice(0, 4);
  const RestReactionsTooltip = (
    <HStack alignItems="center" gap="xxs">
      {reactions &&
        reactionsKeys
          ?.slice(4)
          ?.map((reaction) => (
            <Reaction
              key={reaction}
              emoji={reaction}
              users={reactions[reaction as keyof typeof reactions]}
              hideTooltip
            />
          ))}
    </HStack>
  );

  return (
    <>
      {fromNotification ? null : (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <WidgetBox
            m="xxs"
            bg={isUserMood ? "brand06" : null}
            borderColor="line01"
            borderWidth={hidden && !isUserMood ? 1 : 0}
            borderStyle="solid"
          >
            <VStack gap="xs">
              {/* Hidden mood badge */}
              {hidden && (
                <Flex justifyContent="center">
                  <Icon name="lock" size="small" color="text01" />
                </Flex>
              )}

              {moodContent}

              {/* Actions */}
              <Flex alignItems="center" position="relative" minHeight={29}>
                {reactionsKeys && showedReactions && (
                  <HStack alignItems="center" gap="xxs">
                    <HStack alignItems="center" gap="xxs">
                      {showedReactions.map((reaction) => (
                        <Reaction
                          key={reaction}
                          emoji={reaction}
                          users={reactions[reaction as keyof typeof reactions]}
                        />
                      ))}
                    </HStack>
                    {showedReactions.length < reactionsKeys.length && (
                      <Tooltip
                        content={RestReactionsTooltip}
                        immediate
                        interactive
                      >
                        <Text
                          textStyle="body"
                          color="text01"
                          style={{ cursor: "default" }}
                        >
                          +{reactionsKeys.length - showedReactions.length}
                        </Text>
                      </Tooltip>
                    )}
                  </HStack>
                )}
                <HStack
                  justifyContent="flex-end"
                  alignItems="center"
                  gap="xxs"
                  ml="auto"
                >
                  {
                    <Box>
                      <Tooltip content="Reacciona" hideOnClick>
                        <S.ActionButton onClick={() => setOpenEmojis(true)}>
                          <Icon name="smilingFace" width={20} height={20} />
                        </S.ActionButton>
                      </Tooltip>
                      {openEmojis && (
                        <EmojiSelector
                          reactions
                          top
                          action={updateReactions}
                          isOpen={openEmojis}
                          close={() => setOpenEmojis(false)}
                          height={295}
                        />
                      )}
                    </Box>
                  }

                  <Tooltip content="Respuestas" hideOnClick>
                    <S.ActionButton onClick={toggleCommentsModal}>
                      <Icon name="comment" width={20} height={20} />
                      <Text textStyle="button" color="text01">
                        {repliesCount > 0 ? repliesCount : ""}
                      </Text>
                    </S.ActionButton>
                  </Tooltip>

                  {isUserMood && (
                    <Button
                      appearance="primary"
                      leftIcon="more"
                      tooltip="Opciones"
                      optionsPosition="up-left"
                      options={[
                        {
                          title: "Editar",
                          icon: "edit",
                          action: toggleUpdateModal,
                        },
                        {
                          title: "Eliminar",
                          icon: "trash",
                          action: toggleDeleteModal,
                        },
                      ]}
                    />
                  )}
                </HStack>
              </Flex>
            </VStack>
          </WidgetBox>
        </motion.div>
      )}

      {/* Delete modal */}
      <Modal
        isOpen={isDeleteOpen}
        hide={toggleDeleteModal}
        size="S"
        title="Eliminar estado de ánimo"
        mainAction={{
          title: "Eliminar",
          action: handleDelete,
        }}
        secondaryAction={{ title: "Cancelar", action: toggleDeleteModal }}
      >
        <Box py="s" px="xs">
          <Text textStyle="body" color="text01">
            ¿Seguro que quieres eliminar este estado de ánimo?
          </Text>
        </Box>
      </Modal>

      {/* Update modal */}
      <Modal
        isOpen={isUpdateOpen}
        hide={toggleUpdateModal}
        title="Modifica el estado de ánimo"
        size="S"
        height="auto"
        allowOverflow
      >
        <MoodWidget update={moodData} onSubmit={handleOnUpdate} />
      </Modal>

      {isCommentsOpen && (
        <MoodReplyModal
          isOpen={isCommentsOpen}
          toggleModal={toggleCommentsModal}
          moodContent={moodContent}
          id={id}
          repliesCount={repliesCount}
          setRepliesCount={setRepliesCount}
          fromNotification={fromNotification}
        />
      )}
    </>
  );
};

export default MoodCard;
