import React, {
  MutableRefObject,
  useCallback,
  useRef,
  useState,
  useEffect,
} from "react";
import { VStack, Columns, Column, Box, Flex } from "@sqymagma/elements";
import { useViewportSpy } from "beautiful-react-hooks";

import { remoteApi } from "@services";
import { MoodState, MoodStats, SelectOption } from "@types";
import { Loader, Select } from "@elements";

import Timeline from "./Timeline";
import AverageChart from "./AverageChart";
import DayChart from "./DayChart";
import AverageCard from "./AverageCard";

const itemsPerPage = 10; // number of moods to retrieve with lazy load

const Mood = () => {
  const [moods, setMoods] = useState<Array<MoodState>>();
  const [stats, setStats] = useState<MoodStats>();
  const timelineRef = useRef<HTMLDivElement>(null);

  const filterOptions: Array<SelectOption> = [
    {
      label: "Ver todos los mensajes",
      value: "all",
    },
    {
      label: "Ver mensajes privados",
      value: "hidden",
    },
    {
      label: "Ver mis mensajes",
      value: "own",
    },
  ];
  const [moodsFilter, setMoodsFilter] = useState<SelectOption>(
    filterOptions[0]
  );

  const loadMoreRef = useRef<HTMLDivElement>(null);
  const isLoadMoreVisible = useViewportSpy(
    loadMoreRef as unknown as MutableRefObject<HTMLElement>
  );
  const [showLoadMore, setShowLoadMore] = useState(true);
  const loadMoreMoods = showLoadMore && !!isLoadMoreVisible;
  const lastItemTimestamp = moods?.[moods.length - 1]?.created._seconds;

  const setRetrievedMoods = (moods: Array<MoodState>) => {
    setMoods((state = []) => [...state, ...moods]);
    !moods?.length && setShowLoadMore(false);
  };

  const updateMoodData = (moodUpdated: MoodState) => {
    setMoods(
      (state) =>
        state?.map((mood) =>
          mood.id === moodUpdated.id ? { ...mood, ...moodUpdated } : mood
        )
    );
  };

  const getMoods = useCallback(() => {
    remoteApi
      .getMoods(moodsFilter?.value as "all" | "hidden" | "own", {
        itemsPerPage,
        lastItemTimestamp,
      })
      .then(setRetrievedMoods);
  }, [lastItemTimestamp, moodsFilter]);

  const resetMoods = () => {
    setMoods(undefined);
    setShowLoadMore(true);
  };

  const getStats = () =>
    remoteApi.getMoodStats().then((stats) => setStats(stats));

  useEffect(() => {
    getStats();
  }, []);

  useEffect(() => {
    if (!loadMoreMoods || !loadMoreRef.current) return;
    const rect = loadMoreRef.current.getBoundingClientRect();
    const isVisible = rect.top >= 0 && rect.top <= window.innerHeight;
    if (!isVisible) return;
    getMoods();
  }, [lastItemTimestamp, loadMoreMoods, getMoods]);

  useEffect(() => {
    resetMoods();
  }, [moodsFilter]);

  return (
    <VStack gap="s" minHeight="100vh">
      {/* Charts */}
      <Columns hs="l" vs="l">
        <Column width={{ default: "100%", l: "70%", xl: "35%" }}>
          <DayChart data={stats?.todayMoods} />
        </Column>
        <Column width={{ default: "100%", l: "30%", xl: "230px" }}>
          <AverageCard average={stats?.averageToday} users={stats?.userCount} />
        </Column>
        <Column width={{ default: "100%", xl: "45%" }}>
          <AverageChart data={stats?.averageMoods} setStats={setStats} />
        </Column>
      </Columns>

      {/* Timeline */}
      <div ref={timelineRef}>
        <VStack gap="m" mt="xs">
          <Box maxWidth={220}>
            <Select
              value={moodsFilter}
              options={filterOptions}
              onChange={setMoodsFilter}
              isClearable={false}
            />
          </Box>
          <Timeline
            moods={moods}
            resetMoods={resetMoods}
            updateMoodData={updateMoodData}
            getStats={getStats}
            timelineRef={timelineRef}
          />
        </VStack>
      </div>

      {/* loading more spinner */}
      <Box ref={loadMoreRef}>
        <Flex alignItems="center" justifyContent="center">
          {showLoadMore && <Loader />}
        </Flex>
      </Box>
    </VStack>
  );
};

export default Mood;
