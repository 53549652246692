import React, { useRef, useState } from "react";
import { Stack } from "@sqymagma/elements";
import { useHistory } from "react-router";

import { IconNames } from "@elements/Icon";
import { Icon, Tooltip } from "@elements";

import * as S from "./style";
import { AnimatePresence } from "framer-motion";
import { useHandleClickOutside } from "@hooks";

const Button = (props: Props) => {
  const {
    href,
    to,
    action,
    rightIcon,
    leftIcon,
    children,
    animateHover = false,
    tooltip,
    options,
    optionsPosition = "down-right",
    ...rest
  } = props;
  const { disabled = false, color, appearance } = rest;

  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => setIsMenuOpen((isOpen) => !isOpen);

  const handleClickOutside = (e: MouseEvent) => {
    if (menuRef.current && menuRef.current.contains(e.target as Node)) return;
    toggleMenu();
  };
  useHandleClickOutside(isMenuOpen, handleClickOutside);

  const handleClick = () => {
    if (disabled) return;
    action && action();
    options?.length && toggleMenu();
    to && history.push(to);
  };

  const isSecondary = appearance === "secondary";
  const isTertiary = appearance === "tertiary";

  const optionPositions = optionsPosition.split("-") as Array<
    "down" | "up" | "left" | "right"
  >;

  return (
    <Tooltip content={tooltip} hideOnClick>
      <S.StyledButton
        ref={menuRef}
        href={href}
        to={to}
        onClick={handleClick}
        as={href ? "a" : "button"}
        $animateHover={animateHover}
        {...rest}
      >
        <Stack alignItems="flex-end" hs={isTertiary ? "xxxs" : "xxs"}>
          {leftIcon && (
            <S.IconPlace
              disabled={disabled}
              color={color}
              className="left-icon"
            >
              <Icon
                name={leftIcon}
                height={16}
                color={
                  disabled ? "disabled01" : isSecondary ? "text02" : "text01"
                }
              />
            </S.IconPlace>
          )}
          {children && (
            <S.Span disabled={disabled} color={color}>
              {children}
            </S.Span>
          )}
          {rightIcon && (
            <S.IconPlace
              disabled={disabled}
              color={color}
              className="right-icon"
            >
              <Icon
                name={rightIcon}
                height={16}
                color={
                  disabled ? "disabled01" : isSecondary ? "text02" : "text01"
                }
              />
            </S.IconPlace>
          )}
        </Stack>

        <AnimatePresence>
          {isMenuOpen && (
            <S.Menu
              className="button-menu"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: "easeInOut", duration: 0.15 }}
              onClick={toggleMenu}
              $position={optionPositions}
            >
              {options?.map((option) => {
                const handleClick = () => {
                  option.action && option.action();
                  toggleMenu();
                };
                return (
                  <S.MenuItem key={option.title} onClick={handleClick}>
                    {option.icon && (
                      <Icon name={option.icon} size="small" color="text01" />
                    )}
                    {option.title}
                  </S.MenuItem>
                );
              })}
            </S.Menu>
          )}
        </AnimatePresence>
      </S.StyledButton>
    </Tooltip>
  );
};

interface Props {
  children?: string;
  href?: string;
  to?: string;
  action?: () => void;
  rightIcon?: IconNames;
  leftIcon?: IconNames;
  animateHover?: boolean;
  disabled?: boolean;
  color?: string;
  appearance?: "primary" | "secondary" | "tertiary";
  target?: string;
  rounded?: boolean;
  shadow?: boolean;
  tooltip?: string;
  options?: Array<{ title: string; icon?: IconNames; action: () => void }>;
  optionsPosition?:
    | "down"
    | "up"
    | "left"
    | "right"
    | "down-left"
    | "down-right"
    | "up-left"
    | "up-right";
}

export default Button;
