import React, { useRef } from "react";
import EmojiPicker, {
  Categories,
  EmojiClickData,
  Theme,
} from "emoji-picker-react";
import { THEMES } from "@constants";
import { useStyle } from "@contexts";
import { useHandleClickOutside } from "@hooks";

import * as S from "./style";

const EmojiSelector = (props: Props) => {
  const { action, isOpen, close, height = 375, reactions, top } = props;
  const { theme } = useStyle();
  const isDarkTheme = theme === THEMES.dark;

  const emojisRef = useRef<HTMLDivElement>(null);

  const handleClickOutsideEmojis = (e: MouseEvent) => {
    if (emojisRef.current && emojisRef.current.contains(e.target as Node))
      return;
    close();
  };
  useHandleClickOutside(isOpen, handleClickOutsideEmojis);

  return (
    <S.Emojis ref={emojisRef} key={`emojis-${theme}`} $top={top}>
      <EmojiPicker
        theme={isDarkTheme ? Theme.DARK : Theme.LIGHT}
        searchPlaceHolder="Buscar un emoji"
        onEmojiClick={action}
        previewConfig={{ showPreview: false }}
        height={height}
        reactionsDefaultOpen={reactions}
        onReactionClick={action}
        reactions={[
          "1f44d",
          "2764-fe0f",
          "1f600",
          "1f602",
          "1f62e",
          "1f622",
          "1f621",
        ]}
        categories={[
          {
            category: Categories.SUGGESTED,
            name: "Usados con frecuencia",
          },
          {
            category: Categories.SMILEYS_PEOPLE,
            name: "Smileys y personas",
          },
          {
            category: Categories.ANIMALS_NATURE,
            name: "Animales y naturaleza",
          },
          { category: Categories.FOOD_DRINK, name: "Comida y bebida" },
          {
            category: Categories.TRAVEL_PLACES,
            name: "Viajes y lugares",
          },
          { category: Categories.ACTIVITIES, name: "Actividades" },
          { category: Categories.OBJECTS, name: "Objetos" },
          { category: Categories.SYMBOLS, name: "Símbolos" },
          { category: Categories.FLAGS, name: "Banderas" },
        ]}
      />
    </S.Emojis>
  );
};

interface Props {
  action: (data: EmojiClickData) => void;
  isOpen: boolean;
  close: () => void;
  height?: number;
  reactions?: boolean;
  top?: boolean;
}

export type { EmojiClickData };

export default EmojiSelector;
