import styled from "styled-components";

const Emojis = styled.div<{ $top?: boolean }>`
  position: absolute;
  z-index: 9999;
  top: ${(p) => (p.$top ? "-100%" : "50%")};
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(p) => p.theme.colors.senaryBackground};
  border-radius: ${(p) => p.theme.radii.m};
  box-shadow: ${(p) => p.theme.shadows.tiny};
  border: 1px solid ${(p) => p.theme.colors.line07};
  overflow: hidden;

  .EmojiPickerReact {
    --epr-bg-color: ${(p) => p.theme.colors.senaryBackground};
    --epr-reactions-bg-color: ${(p) => p.theme.colors.senaryBackground};
    --epr-category-label-bg-color: ${(p) => p.theme.colors.senaryBackground};
    --epr-text-color: ${(p) => p.theme.colors.text01};
    --epr-hover-bg-color: ${(p) => `${p.theme.colors.brand01}15`};
    --epr-focus-bg-color: ${(p) => `${p.theme.colors.brand01}25`};
    --epr-highlight-color: ${(p) => p.theme.colors.brand01};
    --epr-search-border-color: ${(p) => p.theme.colors.line01};
    --epr-picker-border-radius: 0;

    border: none !important;

    * {
      font-family: "Gilroy", sans-serif;
    }

    .epr-category-nav {
      filter: hue-rotate(75deg);
    }

    li.epr-emoji-category > .epr-emoji-category-label {
      text-transform: initial;
    }
  }

  & aside.epr-reactions ul li:last-child button {
    background-color: ${(p) => p.theme.colors.interactive05};
    &:after {
      background-image: none;
      content: "+";
      color: ${(p) => p.theme.colors.textOnInteractive05};
    }
    &:hover {
      background-color: ${(p) => p.theme.colors.interactive05Hover};
    }
    &:hover::after {
      color: ${(p) => p.theme.colors.textOnInteractive05Hover};
    }
  }
`;

export { Emojis };
