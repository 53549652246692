import { requestAccessToken, refreshToken } from "./google";
import {
  getHolidays,
  getVacations,
  getTimesheets,
  getResources,
} from "./hubplanner";
import {
  createApplause,
  updateApplause,
  deleteApplause,
  getRemainingClaps,
  getApplausesStats,
  getApplausesReceived,
  getApplausesSent,
  getLastYearApplauses,
} from "./applauses";
import {
  createMood,
  updateMood,
  getMoods,
  deleteMood,
  getMoodStats,
  getMoodsById,
  createMoodReply,
  updateMoodReply,
  deleteMoodReply,
  getMoodReplies,
  updateMoodReactions,
} from "./mood";

const remoteApi = {
  requestAccessToken,
  refreshToken,
  getHolidays,
  getVacations,
  getTimesheets,
  getResources,
  createApplause,
  updateApplause,
  getRemainingClaps,
  getApplausesStats,
  getApplausesReceived,
  getApplausesSent,
  getLastYearApplauses,
  deleteApplause,
  createMood,
  updateMood,
  getMoods,
  deleteMood,
  getMoodStats,
  getMoodsById,
  createMoodReply,
  updateMoodReply,
  deleteMoodReply,
  getMoodReplies,
  updateMoodReactions,
};

export default remoteApi;
