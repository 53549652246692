import styled from "styled-components";
import { Box } from "@sqymagma/elements";
import { theme } from "@sqymagma/theme";
import { motion } from "framer-motion";

const Header = styled(Box)`
  min-height: 90px;
  justify-content: ${(p) => (p.$isHome ? "space-between" : "flex-end")};
`;

const Picture = styled.img`
  height: 66px;
  width: 66px;
  border-radius: 50%;
`;

const WeatherWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Menu = styled(motion.div)`
  position: absolute;
  right: 0;
  top: 22px;
  border-radius: ${(p) => p.theme.radii.s};
  border: 1px solid ${(p) => p.theme.colors.line01};
  overflow: hidden;
  box-shadow: ${(p) => p.theme.shadows.small};
`;

const MenuItem = styled.div`
  ${theme("textStyle.body")};
  white-space: nowrap;
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.xs};
  background-color: ${(p) => p.theme.colors.tertiaryBackground};
  color: ${(p) => p.theme.colors.text01};
  cursor: pointer;

  &:hover {
    background-color: ${(p) => p.theme.colors.interactive04Hover};
  }
`;

const Badge = styled.div`
  position: absolute;
  background-color: ${(p) => p.theme.colors.info};
  border: 1px solid ${(p) => p.theme.colors.primaryBackground};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  right: -2px;
  top: -2px;
`;

export { Header, Picture, WeatherWrapper, Menu, MenuItem, Badge };
