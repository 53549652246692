import { GROUPS } from "./constants";
import { Camel } from "@types";
import { format } from "date-fns";
import { googleApi } from "@services";

export type Team = {
  name: string;
  participants: Array<string>;
  camelSteps: Array<Camel>;
  totalSteps: number;
  extraSteps: number;
};

export type GeneralClassificationTeam = {
  name: string;
  totalSteps: number;
};

const getGroupsSteps = (weekSteps: Array<Camel>) => {
  const stepsByTeams: Array<Team> = GROUPS;
  stepsByTeams.forEach((team: Team) => {
    weekSteps.forEach((camel) => {
      const findPerson = team.camelSteps.findIndex(
        (element) => element.email === camel.email
      );
      if (team.participants.includes(camel.email) && findPerson === -1) {
        team.camelSteps.push(camel);
      } else if (findPerson !== -1) {
        team.camelSteps[findPerson] = camel;
      }
    });
    team.totalSteps = team.camelSteps.reduce(
      (prev, curr) => prev + curr.steps,
      team.extraSteps
    );
  });

  return stepsByTeams;
};

const writeGeneralClassification = async (stepsByTeams: Array<Team>) => {
  const today = format(new Date(), "dd/MM/yyyy");
  if (new Date().getDay() !== 1) return;

  const { REACT_APP_CAMELS_GSHEET_ID: spreadsheetId } = process.env;
  if (!spreadsheetId) return;
  const payload = { spreadsheetId, range: "Retos!A1:ZZ100" };

  const excelData = await googleApi.getSheet(payload);
  const rows = excelData;
  if (rows && rows[0][rows[0].length - 1] !== today) {
    rows[0].push(today);
    rows.forEach((value, index) => {
      if (index !== 0) {
        value[rows[0].length - 1] = "0";
      }
    });
  }

  if (rows) {
    stepsByTeams.forEach((team: Team) => {
      rows.forEach((row) => {
        if (row[0] === team.name) {
          if (row[rows[0].length - 1]) {
            row[rows[0].length - 1] = team.totalSteps;
          } else {
            row.push(team.totalSteps);
          }
        }
      });
    });
  }

  if (rows) googleApi.writeSheet(spreadsheetId, "Retos!A1:ZZ100", rows);
};

const getGeneralClassification = async () => {
  const today = format(new Date(), "dd/MM/yyyy");
  const { REACT_APP_CAMELS_GSHEET_ID: spreadsheetId } = process.env;
  if (!spreadsheetId) return;
  const payload = { spreadsheetId, range: "Retos!A1:ZZ100" };

  const excelData = await googleApi.getSheet(payload);
  const rows = excelData;
  const teams: Array<GeneralClassificationTeam> = [];

  if (rows && rows[0][rows[0].length - 1] !== today) {
    rows[0].push(today);
  }
  if (rows) {
    rows.forEach((value, index) => {
      const team: GeneralClassificationTeam = {
        name: "",
        totalSteps: 0,
      };
      if (index !== 0) {
        team.name = value[0];
        team.totalSteps = value
          .slice(1)
          .reduce((prev, current) => Number(prev) + Number(current), 0);
        teams.push(team);
      }
    });
  }

  return teams;
};

export { getGroupsSteps, getGeneralClassification, writeGeneralClassification };
