import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useApp } from "@contexts";
import firebase from "@database";
import wrappedImg from "@images/wrapped.png";
import { Flex, Text, Box } from "@sqymagma/elements";

import * as S from "./style";

const WrappedWidget = () => {
  const { wrappedDay } = useApp();
  const history = useHistory();

  const [year, setYear] = useState<string>();

  useEffect(() => {
    if (wrappedDay) {
      const today = new Date();
      const day = today.getDate();
      const month = today.getMonth();
      const year = today.getFullYear();

      setYear(
        `${day >= wrappedDay && month === 11 ? year : year - 1}`.substring(2)
      );
    }
  }, [wrappedDay]);

  const handleOnClick = () => {
    history.push("/wrapped");
    firebase.logEvent("widget_click", { widget_title: "Wrapped" });
  };

  return (
    <S.Wrapper onClick={handleOnClick}>
      <Flex
        position="relative"
        flexDirection={{ s: "column", l: "row", xl: "column", xxl: "row" }}
        justifyContent="space-between"
        alignItems={{
          default: "center",
          s: "flex-start",
          l: "center",
          xl: "flex-start",
          xxl: "center",
        }}
        style={{ zIndex: 1, gap: "16px" }}
      >
        <Box>
          {["Se", "cuo", "yas"].map((value) => (
            <Text
              key={value}
              color="#77EFE1"
              display="block"
              style={{
                fontFamily: "'Abril Fatface', serif",
                fontSize: "56px",
                lineHeight: "40px",
                letterSpacing: "1.14px",
              }}
            >
              {value}

              {value === "yas" && year && (
                <Text
                  color="#FFF"
                  style={{
                    fontSize: "41px",
                    lineHeight: "44px",
                    letterSpacing: "normal",
                  }}
                >
                  ‘{year}
                </Text>
              )}
            </Text>
          ))}
        </Box>

        <Box textAlign="center" width={{ default: "100%" }}>
          <img
            src={wrappedImg}
            alt="Wrapped"
            style={{ width: "100%", maxWidth: "220px" }}
          />
        </Box>
      </Flex>
    </S.Wrapper>
  );
};

export default WrappedWidget;
