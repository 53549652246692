import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  padding: 24px;
  border-radius: 20px;
  background: #543a80;
  overflow: hidden;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    top: 30%;
    left: 0;
    width: 352.483px;
    height: 386.356px;
    transform: rotate(56.676deg);
    border-radius: 386.356px;
    background: linear-gradient(
      180deg,
      rgba(207, 175, 215, 0.8) 0%,
      rgba(150, 68, 255, 0.8) 100%
    );
    filter: blur(100px);
    transition: transform 0.5s ease-in-out;
  }

  &:hover {
    &::after {
      transform: rotate(56.676deg) scale(1.4);
    }
  }
`;
