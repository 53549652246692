import styled, { keyframes } from "styled-components";
import { theme } from "@sqymagma/theme";

const heartbeat = keyframes`
from {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
10% {
  -webkit-transform: scale(0.91);
          transform: scale(0.91);
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}
17% {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
33% {
  -webkit-transform: scale(0.87);
          transform: scale(0.87);
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}
45% {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
`;

const rotate = keyframes`
0% {
  -webkit-transform: scale(1) rotateZ(0);
          transform: scale(1) rotateZ(0);
}
50% {
  -webkit-transform: scale(1.2) rotateZ(180deg);
          transform: scale(1.2) rotateZ(180deg);
}
100% {
  -webkit-transform: scale(1) rotateZ(360deg);
          transform: scale(1) rotateZ(360deg);
}
`;

const Heart = styled.img`
  will-change: transform;
  &:hover {
    animation: ${heartbeat} 1.5s ease-in-out infinite both;
  }
`;

const Flower = styled.img`
  will-change: transform;
  &:hover {
    animation: ${rotate} 1s ease-in-out both;
  }
`;

const IconBox = styled.div`
  position: absolute;
  top: -40px;
  left: 40%;
  background-color: ${theme("colors.secondaryBackground")};
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextBox = styled.div`
  color: ${theme("colors.dark")};
  ${theme("textStyle.body")};
  position: absolute;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${theme("colors.gold")};
  width: calc(100% + 30px);
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
`;

const ArrowLeft = styled.div`
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid ${theme("colors.primaryBackground")};
  position: absolute;
  right: -5px;
`;

const ArrowRight = styled.div`
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid ${theme("colors.primaryBackground")};
  position: absolute;
  left: -5px;
`;

const FeaturedBox = styled.div`
  background-color: ${theme("colors.tertiaryBackground")};
  border: 2px solid ${theme("colors.secondaryBackground")};
  border-radius: ${theme("radii.m")};
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: ${theme("spacing.s")};
  padding: ${theme("spacing.m")};
`;

const AnimationWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: ${(p) => p.theme.radii.l};
`;

const Animation = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(1.6);
  > div {
    height: 100%;
  }
`;

const PersonWrapper = styled.div`
  position: relative;
`;

const Wreath = styled.div`
  position: absolute;
  left: 50%;
  top: 66%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const ToolBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  margin-inline: auto;
  gap: ${theme("spacing.xs")};
`;

export {
  IconBox,
  TextBox,
  ArrowLeft,
  ArrowRight,
  FeaturedBox,
  Heart,
  Flower,
  AnimationWrapper,
  Animation,
  PersonWrapper,
  Wreath,
  ToolBar,
};
