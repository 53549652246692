import { useEffect } from "react";

import { useKeyboard } from "@hooks";
import { useApp, useStyle, useToast } from "@contexts";
import packageJson from "@packageJson";
import { THEMES } from "@constants";

const Keyboard = () => {
  const { changeTheme } = useStyle();
  const { addToast } = useToast();
  const {
    config: { openVideoPopup },
  } = useApp();

  const openMeet = useKeyboard(["control", "m"]);
  const lightTheme = useKeyboard(["control", "l"]);
  const darkTheme = useKeyboard(["control", "d"]);

  // development purposes
  const showAppVersion = useKeyboard(["control", "a", "v"]);
  const copyToken = useKeyboard(["control", "t", "k"]);

  useEffect(() => {
    openMeet &&
      window.open(
        "https://meet.new/",
        "_blank",
        openVideoPopup ? "popup" : undefined
      );

    lightTheme && changeTheme(THEMES.light);

    darkTheme && changeTheme(THEMES.dark);

    showAppVersion && addToast(`${packageJson.name} ${packageJson.version}`);

    if (copyToken) {
      const token = localStorage.getItem("token")?.replace(/"/g, "");
      if (token) {
        navigator.clipboard.writeText(token);
        addToast("Token copiado en el portapapeles");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openMeet, showAppVersion, lightTheme, darkTheme, copyToken]);

  return null;
};

export default Keyboard;
