const GROUPS = [
  {
    name: "Team SmartCardia",
    participants: [
      "isaac.morcillo@secuoyas.com",
      "miguel.tallon@secuoyas.com",
    ],
    camelSteps: [],
    totalSteps: 0,
    extraSteps: 200000
  },
  {
    name: "Team Sara",
    participants: [
      "maite.antolin@secuoyas.com",
      "alvaro.sanchez@secuoyas.com",
      "sara.regueira@secuoyas.com",
      "enrique.sanz@secuoyas.com",
      "sergio.rodenas@secuoyas.com",
      "fernando.demorais@secuoyas.com",
    ],
    camelSteps: [],
    totalSteps: 0,
    extraSteps: 0
  },
  {
    name: "Team Jorge",
    participants: [
      "jorge.fernandez@secuoyas.com",
      "gonzalo.hernandez@secuoyas.com",
      "luisa.rodriguez@secuoyas.com",
    ],
    camelSteps: [],
    totalSteps: 0,
    extraSteps: 150000
  },
  {
    name: "Team Majo",
    participants: [
      "majo.dejuan@secuoyas.com",
      "carlos.denova@secuoyas.com",
    ],
    camelSteps: [],
    totalSteps: 0,
    extraSteps: 200000
  },
  {
    name: "Team Gloria",
    participants: [
      "alba.lopez@secuoyas.com",
      "ana.jaime@secuoyas.com",
      "gloria.rodrigo@secuoyas.com",
      "diana.hidalgo@secuoyas.com",
    ],
    camelSteps: [],
    totalSteps: 0,
    extraSteps: 100000
  },
  {
    name: "Team general",
    participants: [
      "ana.gonzalez@secuoyas.com",
      "carmen.martin@secuoyas.com",
      "gonzalo.villar@secuoyas.com",
    ],
    camelSteps: [],
    totalSteps: 0,
    extraSteps: 150000
  },
];

export { GROUPS };
